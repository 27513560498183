
import Header from '@/components/commons/header';
import StepsSidebar from '@/components/commons/steps-sidebar';
import { Montserrat } from '@next/font/google'
import SidebarContextProvider from '@/context/sidebar-context';
import '@/styles/globals.css'

import type { AppProps } from 'next/app'
import IdentityContextProvider from '@/context/identity-context';

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: 'variable',
  variable: '--font-montserrat',
})

export default function App({ Component, pageProps }: AppProps) {
  return (
    <SidebarContextProvider>
      <IdentityContextProvider>
        <main className={`${montserrat.variable} font-sans`}>
          <Header />
          <div className="
            flex flex-col bg-[#f9f9f9] min-h-screen pt-[80px]
            lg:bg-transparent lg:pt-[127px]
          ">
            
            <Component {...pageProps} />
          </div>
          <StepsSidebar
            videoRecorded={false}
          />
        </main>
      </IdentityContextProvider>
    </SidebarContextProvider>
  );
}
