import { SidebarContext } from "@/context/sidebar-context";
import { CandidatesStepsSidebar } from "@flatworldco/nextjs-components";
import React, { useContext } from "react";

interface Props {
  videoRecorded: boolean;
}

const StepsSidebar: React.FC<Props> = (props) => {
  const { videoRecorded } = props;

  const { open, setOpen } = useContext(SidebarContext);

  return (
    <CandidatesStepsSidebar
      sidebarOpen={open}
      setSidebarOpen={setOpen}
      videoRecorded={videoRecorded}
    />
  );
};

export default StepsSidebar;
