import validateNumberParam from "@/lib/validate-number-param";
import validateStringParam from "@/lib/validate-string-param";
import { useRouter } from "next/router";
import React, { createContext, ReactNode, useEffect, useState } from "react";

interface Props {
  children: ReactNode;
}

interface IdentityData {
  ready: boolean;
  togglJobOpening: number | null;
  togglId: number | null;
  token: string | null;
  roleUid: string | null;
}

export const IdentityContext = createContext<IdentityData>({
  ready: false,
  togglJobOpening: null,
  togglId: null,
  token: null,
  roleUid: null,
});

const IdentityContextProvider: React.FC<Props> = (props) => {
  const { children } = props;

  const [ready, setReady] = useState(false);
  const [togglId, setTogglId] = useState<number | null>(null);
  const [togglJobOpening, setTogglJobOpening] = useState<number | null>(null);
  const [token, setToken] = useState(null);
  const [roleUid, setRoleUid] = useState<string | null>(null);

  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;

    const { query } = router;

    const { job_opening_id, candidate_id, session, rid } = query;

    const queryTogglId = returnValidated(candidate_id, validateNumberParam);

    const queryTogglJobOpening = returnValidated(
      job_opening_id,
      validateStringParam
    );

    const queryToken = returnValidated(session, validateStringParam);

    const queryRoleUid = returnValidated(rid, validateStringParam);

    try {
      if (queryTogglJobOpening) {
        localStorage.setItem("toggl_job_opening_id", `${queryTogglJobOpening}`);
      }

      if (queryTogglId) {
        localStorage.setItem("toggl_id", `${queryTogglId}`);
      }

      if (queryToken) {
        localStorage.setItem("token", `${queryToken}`);
      }

      if (queryRoleUid) {
        localStorage.setItem("role_uid", `${queryRoleUid}`);
      }

      const storageTogglJobOpening = returnValidated(
        localStorage.getItem("toggl_job_opening_id"),
        validateStringParam
      );

      const storageTogglId = returnValidated(
        localStorage.getItem("toggl_id"),
        validateNumberParam
      );

      const storageToken = returnValidated(
        localStorage.getItem("token"),
        validateStringParam
      );

      const storageRoleUid = returnValidated(
        localStorage.getItem("role_uid"),
        validateStringParam
      );

      setTogglJobOpening(queryTogglJobOpening || storageTogglJobOpening);
      setTogglId(queryTogglId || storageTogglId);
      setRoleUid(queryRoleUid || storageRoleUid);
      setToken(queryToken || storageToken);
      setReady(true);
    } catch (e: any) {
      setTogglJobOpening(queryTogglJobOpening);
      setTogglId(queryTogglId);
      setRoleUid(queryRoleUid);
      setReady(true);
    }
  }, [router]);

  const value = {
    ready,
    togglJobOpening,
    togglId,
    token,
    roleUid,
  };

  return (
    <IdentityContext.Provider value={value}>
      {children}
    </IdentityContext.Provider>
  );
};

export default IdentityContextProvider;

function returnValidated(value: any, validate: (value: any) => boolean) {
  return validate(value) ? value : null;
}
