import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

interface Props { 
  children: ReactNode
}

interface SidebarContext { 
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const SidebarContext = createContext<SidebarContext>({ open: false, setOpen: () => {}})

const SidebarContextProvider : React.FC<Props> = props => {
  const {
    children,
  } = props;

  const [open, setOpen] = useState(false);

  const value = {
    open,
    setOpen
  };

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
}

export default SidebarContextProvider;