import { SidebarContext } from "@/context/sidebar-context";
import { Role } from "@/types/role";
import { CandidatesHeader } from "@flatworldco/nextjs-components";
import { useContext } from "react";

interface Props {
  role?: Role;
}

const Header: React.FC<Props> = (props) => {
  const { role } = props;

  const { open, setOpen } = useContext(SidebarContext);

  return (
    <CandidatesHeader
      role={undefined}
      small={true}
      headerStepText={<>Next Steps</>}
      sidebarOpen={open}
      setSidebarOpen={setOpen}
    />
  );
};

export default Header;
